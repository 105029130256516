import React from 'react'
import { InternalLink } from './Link'
import Img from 'gatsby-image'
import styled from 'styled-components'
import slug from 'slug'
const Container = styled('div')`
  position: relative;
`


const Location = styled('div')`
  a {
    text-shadow: 0px 0px 1em rgba(0,0,0, 0.4), 0px 0px 2em rgba(0,0,0, 0.4), 0px 0px 3em rgba(0,0,0, 0.4);
    font-size: 1rem;
  }
  color: white;
  position: absolute;
  line-height: 1.3;
  bottom: 1.618em;
  right: 1.618em;
  text-align: right;
`

const LocationTags = ({ id, location }) => (
  <Location>
    {location
      .split(", ")
      .map(tag => <InternalLink key={tag} to={`/${slug(tag).toLowerCase()}`}>{tag}</InternalLink>)
      .reduce((prev, curr) => [prev, ", ", curr])}
  </Location>
)


const Image = ({ childImageSharp: { fluid }, fields: { location, created }, ...photo }) => (
  <Container>
    <Img fluid={fluid} alt={location}/>
    <LocationTags id={photo.id} location={location}/>
  </Container>
)

export default Image
