import React from 'react'
import Img from '../components/Img'
const sortByCreation = (a, b) => (new Date(b.photo.fields.iptc.date_time) - new Date(a.photo.fields.iptc.date_time))
import { compose, withPropsOnChange } from 'recompose'

const PhotoList = ({ photos, ...props }) => (
  <section>
  {
    photos.map(({ photo }) => <Img key={photo.id} {...photo}/>)
  }
  </section>
)

export default compose(
  withPropsOnChange(['photos'], ({ photos }) => ({
    photos: photos.sort(sortByCreation)
  }))
)(PhotoList)
