import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import PhotoList from '../components/PhotoList'
import SEO from '../components/SEO'

const Page = ({ pageContext: { tag }, data: { files: { totalCount, photos = [] } } }) => (
  <Layout place={tag.replace(/\-/g, " ")}>
    <PhotoList photos={photos}/>
  </Layout>
)

export default Page

export const query = graphql`
query ($tag: String) {
  files: allFile(
    filter: {
      extension: { in: ["jpeg", "jpg"] },
      dir: { regex: "/photos/" },
      fields: {
        tags: {
          in: [$tag]
        }
      }
    },
    sort: {fields: [birthTime], order: ASC}
  ) {
  totalCount
   photos: edges {
     photo: node {
       id
       relativePath
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
       fields {
        tags
        iptc {
          date_time
        }
        latitude
        longitude
        location
      }
     }
   }
  }
}
`
